<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Adventure starts here 🚀
          </b-card-title>
          <b-alert
            class="mb-2 p-1"
            show
            variant="danger"
          >
            To register an account, you need to fill in all the fields
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- username -->
              <b-form-group
                label="First name"
                label-for="firstname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Firstname"
                  vid="firstname"
                  rules="required"
                >
                  <b-form-input
                    id="firstname"
                    v-model="first_name"
                    name="firstname"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Last name"
                label-for="lastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lastname"
                  vid="lastname"
                  rules="required"
                >
                  <b-form-input
                    id="lastname"
                    v-model="last_name"
                    name="lastname"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Phone"
                label-for="register-phone"
              >
                <VuePhoneNumberInput
                  id="register-phone"
                  v-model="userPhone"
                  z-index="20"
                  name="register-phone"
                  :only-countries="phone_countries"
                  default-country-code="US"
                  @update="userPhoneResult = $event"
                />
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="checkbox-privacy"
              >
                ✔️ I agree to privacy policy & terms
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text" />
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              target="_blank"
              href="https://www.facebook.com/skateboardinglessons/"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="instagram"
              target="_blank"
              href="https://www.instagram.com/skateboardlessons/"
            >
              <feather-icon icon="InstagramIcon" />
            </b-button>
            <b-button
              variant="twitter"
              target="_blank"
              href="https://twitter.com/goskateschool"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="youtube"
              target="_blank"
              href="https://www.youtube.com/c/GoSkate"
            >
              <feather-icon icon="YoutubeIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      first_name: '',
      last_name: '',
      userEmail: '',
      userPhone: '',
      userPhoneResult: null,
      password: '',
      sideImg: require ('@/assets/images/logo/goskate_logo_hor_blue.png'),
      // validation
      required,
      email,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      phone_countries: [
        'US',
        'GB',
        'IE',
        'CA',
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      // console.log('register')
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          const request = {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.userEmail,
            phone: this.userPhoneResult.nationalNumber,
            phone_country: this.userPhoneResult.countryCode,
            password: this.password,
          }
          // console.log(JSON.stringify(request))

          useJwt.register(request)
            .then(response => {
              // console.log(JSON.stringify(response.data))
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(response.data.userData))
              this.$ability.update(response.data.userData.ability)
              this.$router.push('/')
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error)
              if (error.response.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CompassIcon',
                    variant: 'danger',
                    text: 'The user already exists',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'CompassIcon',
                    variant: 'success',
                    text: 'The user has been successfully created',
                  },
                })
              }
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
