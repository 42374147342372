var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-alert',{staticClass:"mb-2 p-1",attrs:{"show":"","variant":"danger"}},[_vm._v(" To register an account, you need to fill in all the fields ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"First name","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"Firstname","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","name":"firstname","state":errors.length > 0 ? false:null,"placeholder":"johndoe"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Last name","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"Lastname","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","name":"lastname","state":errors.length > 0 ? false:null,"placeholder":"johndoe"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"register-phone"}},[_c('VuePhoneNumberInput',{attrs:{"id":"register-phone","z-index":"20","name":"register-phone","only-countries":_vm.phone_countries,"default-country-code":"US"},on:{"update":function($event){_vm.userPhoneResult = $event}},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"checkbox-privacy"}},[_vm._v(" ✔️ I agree to privacy policy & terms ")]),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Sign up ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Sign in instead")])])],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"})]),_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"facebook","target":"_blank","href":"https://www.facebook.com/skateboardinglessons/"}},[_c('feather-icon',{attrs:{"icon":"FacebookIcon"}})],1),_c('b-button',{attrs:{"variant":"instagram","target":"_blank","href":"https://www.instagram.com/skateboardlessons/"}},[_c('feather-icon',{attrs:{"icon":"InstagramIcon"}})],1),_c('b-button',{attrs:{"variant":"twitter","target":"_blank","href":"https://twitter.com/goskateschool"}},[_c('feather-icon',{attrs:{"icon":"TwitterIcon"}})],1),_c('b-button',{attrs:{"variant":"youtube","target":"_blank","href":"https://www.youtube.com/c/GoSkate"}},[_c('feather-icon',{attrs:{"icon":"YoutubeIcon"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }